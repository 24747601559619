<template>
  <el-main>
    <!-- 搜索 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="申请时间：">
        <el-date-picker
          :picker-options="pickerOptions"
          size="small"
          class="search_input"
          v-model="time"
          @change="getTime"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="申请单号：">
        <el-input v-model="withdraw_no" size="small"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small">搜索</el-button>
        <el-button size="small" @click="exportLog">导出</el-button>
        <el-button size="small" type="text" @click="resetSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="status" type="card" @tab-click="handleClick">
      <el-tab-pane :name="item.status" v-for="(item, index) in statusList" :key="index">
        <span slot="label">{{ item.title }}（{{ item.count }}）</span>
      </el-tab-pane>
    </el-tabs>
    <!-- 表格 -->
    <el-table fit :data="list" :header-cell-style="{ 'background-color': '#f8f9fa' }" style="border-left: 1px solid #e8eef1; border-right: 1px solid #e8eef1">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="create_time" label="申请时间" align="center"></el-table-column>
      <el-table-column prop="withdraw_no" label="申请单号" align="center"></el-table-column>
      <el-table-column prop="withdrawal_amount" label="申请提现金额" align="center"></el-table-column>
      <el-table-column prop="remit_amount" label="打款金额" align="center" width="280"></el-table-column>
      <el-table-column prop="order_count" label="结算订单数" align="center"></el-table-column>
      <el-table-column prop="account_type" label="对公/对私/微信" align="center">
        <template v-slot="{ row }">
          {{ row.account_type == 1 ? '对公' : row.account_type == 2 ? '对私' : '微信' }}
        </template>
      </el-table-column>
      <el-table-column prop="bank" label="银行信息" align="center"></el-table-column>
      <el-table-column prop="bank_card" label="公司账号/银行卡号/申请人" align="center">
        <template v-slot="{ row }">
          {{ row.account_type == 1 || row.account_type == 2 ? row.bank_card : row.openid }}
        </template>
      </el-table-column>
      <el-table-column label="提现状态" align="center">
        <template v-slot="{ row }">
          <div style="color: #409eff">{{ row.status == 0 ? '已驳回' : row.status == 1 ? '待审核' : row.status == 2 ? '待结算' : row.status == 3 ? '已结算' : '' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="seeDetail(row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import config from '@/util/config';
export default {
  components: {
    Paging,
  },
  data: function () {
    return {
      total: 0,
      page: 1,
      pageNum: 10,
      search: {
        applytime: null,
      },
      status: 1,
      time: '',
      start_time: '',
      end_time: '',
      withdraw_no: '',
      list: [],
      statusList: [],
    };
  },
  created() {
    this.getList();
    this.getStatus();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      this.getList();
    },
    getTime(val) {
      this.start_time = val[0].getTime() / 1000;
      this.end_time = val[1].getTime() / 1000;
    },
    seeDetail(row) {
      this.$router.push({
        path: '/finance/withdrawalrecordDetail',
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    //导出提现记录
    exportLog() {
      this.$axios
        .post(this.$api.finance.withdrawLogExport, {
          status: this.status,
        })
        .then((res) => {
          if (res.code == 0) {
            let path = config.baseurl + '/' + res.result;
            let a = document.createElement('a');
            a.href = path;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getStatus() {
      this.$axios.post(this.$api.finance.status).then((res) => {
        if (res.code == 0) {
          this.statusList = res.result;
          this.statusList[this.statusList.length - 1].status = 4;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick() {
      this.getList();
    },
    resetSearch() {
      this.withdraw_no = '';
      this.time = '';
      this.start_time = '';
      this.end_time = '';
    },
    getList() {
      this.$axios
        .post(this.$api.finance.withdrawLog, {
          page: this.page,
          rows: this.rows,
          status: this.status == 4 ? 0 : this.status,
          start_time: this.start_time,
          end_time: this.end_time,
          withdraw_no: this.withdraw_no,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list;
            list.forEach((item) => {
              item.create_time = getDateformat(item.create_time);
            });
            this.list = list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
